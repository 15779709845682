.fingerPrintDash {
    height: 100%;
    width: 100%;
    & > .loading {
      height: 100%;
      width: 100%;
      display: grid;
      place-items: center;
    }
    & > iframe {
      height: 100%;
      width: 100%;
    }
  }