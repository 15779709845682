@mixin ScrollBar {
  scrollbar-width: thick;
  transition: all 150ms ease-in-out;
 
  &::-webkit-scrollbar {
    width: 0.5rem;
  
    padding-bottom: 0%;
    height: 0.5rem;              /* height of horizontal scrollbar ← You're missing this */
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
  }
}